import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import Checkbox from '@material-ui/core/Checkbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import TabPanel from '../../widgets/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const TrainingCommitteeForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="trainingCommittee">

                    <div className="row w-100">
                        <div className="col w-100">
                                <table cellPadding="2" className="w-100">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <SelectItem
                                                    parentId={0}
                                                    dataUrl="api/sims/trainingcommittee/GetLookupList?listId=Title"
                                                    id="TitleID"
                                                    key={tabValue}
                                                    label="Title"
                                                    className="w-100"
                                                    form={form}
                                                // required
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="FirstName"
                                                    key={tabValue}
                                                    label="First Name"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="Surname"
                                                    key={tabValue}
                                                    label="Surname"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="IDNumber"
                                                    key={tabValue}
                                                    label="ID Number"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                    validationRegex={/^[0-9]{13}$/g}
                                                    validationMessage="The ID Number must be 13 digits long"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="Initials"
                                                    key={tabValue}
                                                label="Initials"
                                                    className="w-100"

                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/trainingcommittee/GetLookupList?listId=Designation"
                                                    id="DesignationID"
                                                    key={tabValue}
                                                    label="Designation"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="TelephoneNumber"
                                                    key={tabValue}
                                                    label="Telephone Number"
                                                    className="w-100"
                                                    required
                                                form={form}
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="CellPhoneNumber"
                                                    key={tabValue}
                                                    label="Cellphone Number"
                                                    className="w-100"
                                                    required
                                                form={form}
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="FaxNumber"
                                                    key={tabValue}
                                                    label="Fax Number"
                                                    className="w-100"
                                                form={form}
                                                validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="EMail"
                                                    key={tabValue}
                                                    label="Email"
                                                    className="w-100"
                                                    required
                                                form={form}
                                                validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$" 
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                id="NameOfUnion"
                                                    key={tabValue}
                                                    label="NameOfUnion"
                                                    className="w-100"
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                id="PositionInUnion"
                                                    key={tabValue}
                                                label="PositionInUnion"
                                                    className="w-100"
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})