import React from 'react';
import { SelectItem } from '../../../widgets/SelectItem';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();
export const LearnerAvailabilityForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    return (
        <EntityForm ref={ref} viewId="LearnerAvailabilityForm" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="LearnerAvailabilityForm">
                    <table cellPadding="2" width="100%">
                        <tbody>
                            <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=LearnerAvailability"}
                                                id="LearnerAvailabilityID"
                                                key={tabValue}
                                                label="Learner Availability"
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
