import React from 'react';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import { CustomDateTimePicker } from '../../../widgets/CustomDateTimePicker';
import EmployerSearch from '../../employer/EmployerSearch';
import { LookupField } from '../../../widgets/LookupField';
import { SelectItem } from '../../../widgets/SelectItem';


const utils = new ViewUtils();
export const LearnerTrackingAddForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function InterviewStatusCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Completed") {
                return true
            }
            else if (value.Description !== "Completed") {
                return false
            }
        }
    }

    function InterviewOfferCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Yes") {
                return true
            }
            else if (value.Description !== "Yes") {
                return false
            }
        }
    }

    function EmploymentTypeCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Graduate programme" || value.Description === "Contract") {
                return true
            }
            else if (value.Description !== "Graduate programme" || value.Description !== "Contract") {
                return false
            }
        }
    }

    return (
        <EntityForm ref={ref} viewId="LearnerTrackingAdd" values={props.data} dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="LearnerTrackingAdd">
                    <table cellPadding="2" className="w-100">
                        <tbody>
                            <tr>
                                    <td>
                                        <LookupField
                                            id="EmployerID"
                                            label="Employer"
                                            className="w-100"
                                            required
                                            form={form}
                                        >
                                        <EmployerSearch showTitle={true} programmeId={props.employerLearnerID} mode='lookup' dataUrl="api/sims/learnertracking/EmployerSearch"
                                                onLookupValueChange={(value) => {
                                                    form.setLookupSelection("EmployerID", value)
                                                }} autoFetchData={false} selection={props.selection} />
                                        </LookupField>
                                    </td>
                                <td>
                                    <td>
                                        <SelectItem
                                            dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=LearnerTrackingOfferStatus"}
                                            id="LearnerTrackingOfferStatusID"
                                            key={tabValue}
                                            required={InterviewStatusCheck(form.values["LearnerTrackingStatusID"])}
                                            label="Employment Offered"
                                            className="w-100"
                                            form={form}
                                        />
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDateTimePicker id="InterviewDateTime" className="w-100" required={true} label="Interview Date and Time" form={form} />
                                </td>
                                <td>
                                    <SelectItem
                                        dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=LearnerTrackingStatus"}
                                        id="LearnerTrackingStatusID"
                                        key={tabValue}
                                        label="Status of Interview"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=LearnerTrackingOutcome"}
                                        id="LearnerTrackingOutcomeID"
                                        key={tabValue}
                                        required={InterviewStatusCheck(form.values["LearnerTrackingStatusID"])}
                                        label="Outcome of Interview"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                                <td>
                                    <SelectItem
                                        dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=LearnerTrackingTypeOfEmployment"}
                                        id="LearnerTrackingTypeOfEmploymentID"
                                        key={tabValue}
                                        required={InterviewOfferCheck(form.values["LearnerTrackingOfferStatusID"])}
                                        label="Type of Employment"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDateTimePicker id="EmploymentEndDate" className="w-100" required={EmploymentTypeCheck(form.values["LearnerTrackingTypeOfEmploymentID"])} label="Employment End Date" form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="TrackingReferenceNumber"
                                        key={tabValue}
                                        disabled
                                        label="Tracking Reference Number"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
