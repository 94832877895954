import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./DG.css";
import { ViewContainer } from "./../../View.jsx";
import { DGRejectionReasonForm } from './DGRejectionReasonForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";
const utils = new ViewUtils();

const userRef = React.createRef();

class DGRejectionReason extends Component {
    constructor(props) {
        super(props)

        this.state = {
            model: {
                DGFinancialYearID: { Id: 27, Description: "01 April 2025 - 31 March 2026" },
                Name: "",
                Description: "",
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.onMenuSpecificPermissionItem('/DGRejectionReasonSearch').Add || this.props.onMenuSpecificPermissionItem('/DGRejectionReasonSearch').Edit ? true : false }
                    ]
                },
            ]
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close()
        } else if ("save" === buttonId) {
            this.save()
        }
    }

    handleFormValueChange = (values) => {

        if (!utils.isNull(values)) {
            this.setState({
                Name: values.Name
            });
        }
    }

    save() {
        let formValue = userRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            //if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
            //    formValue.id = this.props.location.state.selection;
            //    isAdd = false;
            //}
            if (!utils.isNull(this.state.id)) {
                formValue.id = this.state.id;
                isAdd = false;
            }
            //let data = JSON.stringify(formValue);

            if (isAdd) {
                const url = `api/sims/dgrejectionreasons/insertdgrejectionreasons`;
                utils.saveForm(this, userRef, url, (response) => {
                    this.setState({ message: "Rejection Reason added successfully", messageStyle: "SUCCESS", id: response.EntityId });
                }, this.error);
            } else {
                const url = `api/sims/dgrejectionreasons/updatedgrejectionreasons`;
                utils.updateForm(this, userRef, url, (response) => {
                    this.setState({ message: "Rejection Reason updated successfully", messageStyle: "SUCCESS" });
                }, this.error);
            }
        }
    }

    error(response) {
        this.setState({ message: "A system error has accured. Please contact your system administrator", messageStyle: "ERROR" });

    }

    close() {
        this.props.history.push('/DGRejectionReasonSearch');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
            <p className="breadcrumbs">
                    {"DG Maintenance > Rejection Reason Details"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    enabled={true} config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <DGRejectionReasonForm ref={userRef}
                                    id={this.state.id}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                    editPermission={this.props.menuPermissionItem.Edit}
                                    data={this.state.model}
                                    model={this.state.model}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>

        );
    }
}

export default withRouter(withMenuPermission(DGRejectionReason));
