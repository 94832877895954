import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./DG.css";
import SearchView from "../../widgets/SearchView";
import CardLayout from '../../widgets/Card';
import DGLetterCreationSearchForm from './DGLetterCreationSearchForm';
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";
import { ViewContainer } from "../../View";

const utils = new ViewUtils();

const filterParameters = [

]
const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'DGIntervention', numeric: false, disablePadding: true, label: 'DG Intervention' },
    { id: 'CRMLetterType', numeric: false, disablePadding: true, label: 'CRM Letter Type' },
    { id: 'CRMLetterSalutation', numeric: false, disablePadding: true, label: 'CRM Letter Salutation' },
    { id: 'HeaderA', numeric: false, disablePadding: true, label: 'Header A' },
    { id: 'HeaderB', numeric: false, disablePadding: true, label: 'Header B' },
    { id: 'CRMLetterGreeting', numeric: false, disablePadding: true, label: 'CRM Letter Greeting' },
    { id: 'SignOffName', numeric: false, disablePadding: true, label: 'Sign off Name' },
    { id: 'JobTitle', numeric: false, disablePadding: true, label: 'Job Title' }
];

const formRef = React.createRef();

class DGLetterCreationSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            model: {
                DGFinancialYearID: { Id: 27, Description: "01 April 2025 - 31 March 2026" },
                DGLetterTypeID: "" 
            },
            keyUpSwitch: false
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            id: 'add',
                            label: '',
                            tooltip: 'Add',
                            icon: 'AddIcon',
                            visible: this.props.menuPermissionItem.Add
                        },
                        {
                            id: 'edit',
                            label: '',
                            tooltip: 'Edit',
                            icon: 'EditIcon',
                            disabled: true,
                            visible: this.props.menuPermissionItem.View
                        },
                        {
                            id: 'delete',
                            label: '',
                            tooltip: 'Delete selected letter from records',
                            icon: 'DeleteIcon',
                            disabled: true,
                            visible: this.props.menuPermissionItem.Delete
                        }
                    ]
                }
            ]
        };

        this.searchParameters = [
            { Name: "DGFinancialYearID", Value: { Id: 27, Description: "01 April 2025 - 31 March 2026" } },
            { Name: "DGLetterTypeID", Value: "" }
        ]

    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/DGLetterCreation'
            })
        }
    } 

    handleFormValueChange(values) {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values });
        }
    }

    handleSelection = (selection) => {
        
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer title={""}>
                <p className="breadcrumbs">
                    {"DG Maintenance > Letter Creation"}
                </p>
                <SearchView headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/dglettercreation/getsearch"}
                    entityViewPath='/DGLetterCreation'
                    title=''
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    filterParameters={filterParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="HeaderA"
                    mode={this.props.mode}
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    deleteUrl={"api/sims/dglettercreation/removedglettercreation"}
                    useDeletePost={true}
                    promptTitle={"Remove Letter"}
                    promptContent={"Are you sure you want to remove this Letter ?"}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                ><DGLetterCreationSearchForm onKeyUp={(e) => { this.onkeyup(e) }} ref={formRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)} data={this.state.model}  />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(DGLetterCreationSearch));
