import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import EmployerSearch from '../../employer/EmployerSearch';
import { LookupField } from '../../../widgets/LookupField';


export default function LearnerTrackingSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="LearnerTrackingSearchForm" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta" handleObject={true}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form => <form className={form.classes.container} id="LearnerTrackingSearchForm">
                <div>
                <tr>
                    <td>
                        <LookupField
                            id="EmployerID"
                            label="Employer"
                            //className="w-100"
                            required
                            form={form}
                        >
                            <EmployerSearch showTitle={true} programmeId={props.employerLearnerID} mode='lookup' dataUrl="api/sims/learnertracking/EmployerSearch"
                                onLookupValueChange={(value) => {
                                    form.setLookupSelection("EmployerID", value)
                                }} autoFetchData={false} selection={props.selection} />
                        </LookupField>
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/learnertracking/GetLookupList?listId=LearnerTrackingStatus"}
                            id="LearnerTrackingStatusID"
                            label="Interview Status"
                            //className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/learnertracking/GetLookupList?listId=LearnerTrackingOutcome"}
                            id="LearnerTrackingOutcomeID"
                            label="Interview Outcome"
                            //className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                        </td>
                    </tr>
                </div>
            </form>
            }
        </EntityForm>
    );
}
