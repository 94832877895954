
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../../widgets/Toolbar';
import AlertItem from "../../../widgets/AlertItem";
import Prompt from '../../../widgets/Prompt';
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "./../../../View.jsx";
import { LearnerTrackingAddForm } from "./LearnerTrackingAddForm";
import FormDialog from "../../FormDialog";
import { LearnerAvailabilityForm } from './LearnerAvailabilityForm';
import LearnerTrackingSearchForm from './LearnerTrackingSearchForm';
import SearchView from "../../../widgets/SearchView";

const headCells = [
    { id: 'EmployerName', numeric: false, disablePadding: true, label: 'Employer Name' },
    { id: 'EmploymentOffered', numeric: false, disablePadding: true, label: 'Employment Offered' },
    { id: 'InterviewDateTime', numeric: false, disablePadding: true, label: 'Interview Date and Time' },
    { id: 'InterviewStatus', numeric: false, disablePadding: true, label: 'Interview Status' },
    { id: 'InterviewOutcome', numeric: false, disablePadding: true, label: 'Interview Outcome' },
    { id: 'TypeOfEmployment', numeric: false, disablePadding: true, label: 'Type Of Employment' },
    { id: 'EmploymentEndDate', numeric: false, disablePadding: true, label: 'Employment End Date' },
    { id: 'TrackingReference', numeric: false, disablePadding: true, label: 'Tracking Reference' }
];

const utils = new ViewUtils();
const addFormRef = React.createRef();
const updateFormRef = React.createRef();
const statusFormRef = React.createRef();


class LearnerTrackingSearch extends Component {

    constructor(props) {
        super(props)

        let searchParameters =
            [

                { Name: "LearnerID", Value: this.props.location.state.learnerID },
                { Name: "EmployerID", Value: "0" },
                { Name: "LearnerTrackingStatusID", Value: "0" },
                { Name: "LearnerTrackingOutcomeID", Value: "0" }

            ]

        this.state = {
            linkDialogOpen: false,
            formDialogOpen: false,
            clearGrid: false,
            model: {
                Learner: this.props.location.state.learnerID,
                EmployerID: null,
                LearnerTrackingStatusID: "0",
                LearnerTrackingOutcomeID: "0"
            },
            empmodel: {
                EmployerID: null
            },
            selectedInterview: null,
            propmtOpen: false,
            promptTitle: "Remove interview for learner",
            promptContent: "Are you sure you want to remove this interview from the learner ?",
            unlinkMessage: "",
            unlinkMessageStyle: "",
            updateDialogOpen: false,
            setlearnerName: this.props.location.state.LearnerName,
            setlearnerid: this.props.location.state.learnerID,
            searchParameters: searchParameters,
            showTable: true,
            currentAvailability: null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', icon: 'AddIcon', tooltip: 'Add new interview for this learner', visible: utils.getCurrentRole() == "Learner" ? false : true },
                        { id: 'update', label: '', tooltip: 'Update the status of this interview', disabled: utils.isNull(this.state.selectedInterview), icon: 'UpdateIcon', visible: utils.getCurrentRole() == "Learner" ? false : true },
                        { id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove interview from this learner', disabled: utils.isNull(this.state.selectedInterview), visible: (utils.getCurrentRole() == "Learner" || utils.getCurrentRole() == "Employer") ? false : true },
                        
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'status', label: 'UPDATE LEARNER AVAILABILITY', tooltip: 'Update learner availability', visible: utils.getCurrentRole() == "Employer" ? false : true }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {

        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.state.setlearnerid
                }
            });
        }
        else if ("add" === buttonId) {
            this.setState({ formDialogOpen: true })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("update" === buttonId) {
            this.setState({ updateDialogOpen: true, message: "" })
        }
        else if ("status" === buttonId) {
            this.setState({ statusFormDialogOpen: true, message: "" })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, selectedInterview: null,
                linkDialogOpen: false, showTable: false, statusFormDialogOpen: false
            }, () => {
                this.setState({ showTable: true })
            });

            this.setState({ promptOpen: false }, () => {
                this.getLearnerAvailability();
            })
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false,
                updateDialogOpen: false,
                statusFormDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedInterview: selection[0].Id,
                message: "",
                messageStyle: ""
            });

        } else {
            this.setState({
                selectedInterview: null,
                message: "",
                messageStyle: ""
            });
        }
    }

    successfulInterviewRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedInterview: null, removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase(), showTable: false });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)), showTable: true })
        }
    }

    unsuccessfulInterviewRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeInterview(url) {
        this.setState({ promptOpen: false }, () => {
        })

        let fullUrl = `${url}?id=${this.state.selectedInterview}`;
        utils.invokeUrlDelete(fullUrl, this.successfulInterviewRemoveCallback, this.unsuccessfulInterviewRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    dialogUploadActionHandler(isCancel, added) {
        if (isCancel === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateStatusDialogOpen: false, selectedInterview: null, statusFormDialogOpen: false
            }, () => {
                this.setState({ showTable: true })
            });
        }
    }

    componentDidMount() {
        this.getLearnerAvailability()
    }

    getLearnerAvailability() {
        let url = "api/sims/learnertracking/GetLearnerAvailability?id=" + this.state.setlearnerid;
        utils.invokeUrl(url, (response) => {
            if (!utils.isNull(response.LearnerAvailabilityID)) {
                this.setState({
                    currentAvailability: response.LearnerAvailabilityID.Description
                })
            }
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Learner > Interview Tracking > " + this.state.setlearnerName +
                        " (Learner Availability: " +
                        (this.state.currentAvailability !== null ? this.state.currentAvailability : "NOT YET SET") +
                        ")"}
                </p>
                <AlertItem message={this.state.unlinkMessage} alertStyle={this.state.unlinkMessageStyle} />
                {this.state.showTable === true && (
                    <SearchView headCells={headCells} dataUrl="api/sims/learnertracking/GetLearnerTrackingSearch"
                        model={this.state.model} toolbarConfig={this.toolbarConfig} searchParameters={this.state.searchParameters}
                        onLookupValueChange={this.props.onLookupValueChange}
                        mode={this.props.mode}
                        handleObject={true}
                        buttonClickHandler={this.handleToolbarButtonClick}
                        history={this.props.history}
                        keyUpSwitch={this.state.keyUpSwitch}
                        setSelection={(selection) => { this.handleSelectionChange(selection) }}
                        searchLabel="SEARCH"
                    >
                        <LearnerTrackingSearchForm employerLearnerID={this.state.setlearnerid}
                            data={this.state.model}
                            onKeyUp={(e) => { this.onkeyup(e) }}
                            valueChangeHandler={(values) => {
                                if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                    this.setState({ model : values });
                                }
                            }}
                        />
                    </SearchView>
                )}

                <FormDialog
                    open={this.state.updateDialogOpen}
                    dialogTitle={"Update Interview"}
                    viewRef={this}
                    entityId={this.state.selectedInterview}
                    formRef={updateFormRef}
                    usePost={true}
                    saveUrl={"api/sims/learnertracking/AddEditLearnerTrackingInterview"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <LearnerTrackingAddForm
                        ref={updateFormRef}
                        employerLearnerID={this.state.setlearnerid}
                        id={this.state.selectedInterview}
                        controllerName={"learnertracking"}
                        dataURL='api/sims/learnertracking/GetLearnerInterviewDetails'
                    />
                </FormDialog>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Add Interview"}
                    viewRef={this}
                    formRef={addFormRef}
                    entityInfo={{ LearnerID: this.state.setlearnerid }}
                    saveUrl={"api/sims/learnertracking/AddEditLearnerTrackingInterview"}
                    usePost={true}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <LearnerTrackingAddForm ref={addFormRef} employerLearnerID={this.state.setlearnerid} data={this.state.empmodel} controllerName={"learnertracking"} />
                </FormDialog>

                <FormDialog
                    open={this.state.statusFormDialogOpen}
                    dialogTitle={"Update Availibility"}
                    viewRef={this}
                    formRef={statusFormRef}
                    entityId={this.state.setlearnerid}
                    saveUrl={"api/sims/learnertracking/UpdateLearnerAvailibility"}
                    usePut={true}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <LearnerAvailabilityForm ref={statusFormRef} controllerName={"learnertracking"} id={this.state.setlearnerid} dataURL='api/sims/learnertracking/GetLearnerAvailability' />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeInterview("api/sims/learnertracking/RemoveLearnerInterview") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(LearnerTrackingSearch)
);