import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { SelectItem } from '../../widgets/SelectItem';


export default function LearnerSearchForm(props) {

    return (
        <EntityForm ref={React.createRef()} viewId="LearnerSearch" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta" handleObject={true}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form => <form className={form.classes.container} id="LearnerSearchForm">
                <div className="row search-form-container">
                    <td className="col form-col">
                        <CustomTextField
                            id="IDNo"
                            label="ID Number"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </td>
                    <td className="col form-col">
                        <CustomTextField
                            id="FirstName"
                            label="First Name"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </td>
                    <td className="col form-col">
                        <CustomTextField
                            id="MiddleName"
                            label="Middle Name"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </td>
                    <td className="col form-col">
                        <CustomTextField
                            id="Surname"
                            label="Surname"
                            className="w-100"
                            onKeyPress={props.onKeyUp}
                            InputProps={{
                                readOnly: false,
                            }}
                            form={form}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/learner/GetLookupList?listId=Province"}
                            id="PhysicalProvinceID"
                            label="Physical Province"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/learner/GetLookupList?listId=InterventionList"}
                            id="InterventionListID"
                            label="Learning Intervention"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                    <td>
                        <SelectItem
                            dataUrl={"api/sims/learner/GetLookupList?listId=LearnerAvailability"}
                            id="LearnerAvailabilityID"
                            label="Learner Availability"
                            className="w-150"
                            form={form}
                            InputProps={{
                                readOnly: props.disableFields
                            }}
                        />
                    </td>
                </div>
            </form>
            }
        </EntityForm>
    );
}
